import FormElementMixin from '../mixins/FormElementMixin.js';

Vue.asyncComponent('ak-autocomplete-by-definition', {
    mixins: [FormElementMixin],
    props: {
        allowMultiple: {
            type: Boolean|Number,
            required: false,
            default: false
        },
        bundleId: {
            type: String,
            required: true,
            default: false
        },
        definitionId: {
            type: String,
            required: true,
            default: false
        },
        valueProp: {
            type: String,
            required: false,
            default: 'id'
        },
    },
    data() {
        return {
            search: '',
            highlightedIndex: -1,
            showResults: false,
            results: [],
            valueObjects: [],
            response: null,
            showSearchOptions: false,
            minCharCount: null,
        }
    },
    computed: {
        selected() {
            if ( ! this.currentValue) {
                return [];
            }

            return this.allowMultiple  ? this.currentValue : [this.currentValue];
        },
        selectedItems() {
            let out = [];

            this.selected.forEach(selectedValue => {
                out.push(this.valueObjects.find(item => item[this.valueProp] === selectedValue));
            })

            return out;
        },
        smartSearchTerms: {
            get() {
                return this.response.search.terms.map(term => {
                    if(!term.isLiteral) {
                        return term.text;
                    }
                })
            },
            set(newValue) {
                this.response.search.terms.map(term => {
                    if(!term.isLiteral && !newValue.includes(term.text)) {
                        this.searchLiteral(term.text);
                    }
                });
            }
        },
        smartSearch() {
            let smart = false;
            if(! ('terms' in this.response.search)) {
                return smart;
            }

            this.response.search.terms.forEach(item => {
                if(item.type != "text") {
                    smart = true;
                }
            })

            return smart;
        },
    },
    methods: {
        /**
         * Chech if we need to apply formatting for this column
         * @param key
         * @param value
         * @returns {*}
         */
        applyColumnFormatting(key, value) {

            if (!this.response) {
                return value;
            }

            const formatting = this.response.columns.find(x => x.id === key).formatting;

            // if there is no formatting set in for this value just return the value
            if(typeof formatting == 'undefined') {
                return value;
            }

            // else we apply the formatting
            return this.$applyFormatting(value, formatting);
        },
        /**
         * Get the dataObject by the selected ids
         * @param selectedIds
         * @returns {T[]}
         */
        selectedObjects(selectedIds) {
            if( ! this.valueObjects.length) {
                return [];
            }

            return this.valueObjects.filter( object => selectedIds.includes(object.id));
        },
        /**
         * Search this term text litteral
         * @param text
         */
        searchLiteral(text) {

            const regex = new RegExp(`\w*(?<!")${text}(?!")`);
            this.search = this.search.replace(regex, '"' + text + '"');
            this.filter(this.search);
        },
        /**
         * Toggle the smart search options
         */
        toggleSearchOptions() {
            this.showSearchOptions = !this.showSearchOptions;
        },
        onFocus() {
            this.showResults = true;
        },
        onBlur(e) {
            this.showResults = false;
            this.highlightedIndex = -1;

            if(this.$el.contains(e.relatedTarget)) {
                this.$refs.search.focus();
            }
        },
        async onChange() {
            if(this.search.length < this.minCharCount) {
                return;
            }

            await this.filter(this.search);
        },
        async filter(search) {
            const url = this.$appKitUrl() + '/' + this.bundleId + '/' + this.definitionId;
            this.response = await this.$get(url, {q: search});
            this.minCharCount = this.response.minCharCount;

            this.results = this.response.dataObjects;
        },
        resultActive(result) {
            return this.allowMultiple && this.currentValue ?
                    this.currentValue.includes(result[this.valueProp]) :
                    this.currentValue === result[this.valueProp];
        },
        setResult(result) {
            // if we don't have any results we stop here
            if(! result) {
                return;
            }

            if (this.allowMultiple) {
                if( ! this.currentValue) {
                    this.currentValue = [];
                }

                // check if the selected item isen't already present
                if (this.currentValue.includes(result[this.valueProp])) {
                    return;
                }

                this.valueObjects.push(result);

                this.currentValue.push(result[this.valueProp]);
                this.search = '';
                this.results = this.filter(this.search);
            } else {
                this.valueObjects.push(result);
                this.currentValue = result[this.valueProp];
                this.search = result.label ? result.label : '';
            }
        },
        /** when we press backspace and no search string is present
         * we remove a part of the selected items if multiple
         * else we reset the currentValue
         */
        deleteItem(objectId) {
            if( ! this.currentValue) {
                return;
            }

            if(this.allowMultiple && this.currentValue) {
                const indexToDelete = this.currentValue.findIndex(value => value === objectId);
                this.currentValue.splice(indexToDelete,1);

                const indexToDeleteValueObjects = this.valueObjects.findIndex(value => value.id === objectId);
                this.valueObjects.splice(indexToDeleteValueObjects,1);
            } else {
                this.currentValue = null;
                this.valueObjects = [];
            }
        },
        highlightedUp(e) {
            e.preventDefault();
            let newIndex = this.highlightedIndex + 1;

            if( (this.results.length - 1) < newIndex) {
                newIndex = (this.results.length - 1)
            }

            if(! this.results.length) {
                newIndex = 0;
            }

            this.highlightedIndex = newIndex;
        },
        highlightedDown(e) {
            e.preventDefault();
            let newIndex = this.highlightedIndex - 1;

            if( 0 > newIndex) {
                newIndex = 0
            }

            this.highlightedIndex = newIndex;

        },
        selectHighlighted() {
            this.setResult(this.results[this.highlightedIndex]);
        }
    },
    async created() {
        if (this.value) {
            const url = new URL(this.$appKitUrl() + '/' + this.bundleId + '/' + this.definitionId + '/get-row');
            url.searchParams.append('id', this.value);
            const result = await this.$get(url.toString());

            this.results.push(result);
            this.setResult(result);
        }
    }
}, 'form/controls/ak-autocomplete-by-definition.html');