import FormTrigger from "./FormTrigger";

/**
 * This class describes which matrix field has triggerd an action
 * e.g. validation of a matrix field
 */
export default class MatrixFormTrigger extends FormTrigger {

    /**
     * @param trigger
     */
    constructor(trigger) {
        const matrixTrigger = {
            row: trigger[0],
            widgetId: trigger[1],
        }

        super(matrixTrigger);
    }
}