Vue.asyncComponent('ak-form-group', {
    props: {
        form: {
            type: Object,
        },
        hasError: {
            type: Boolean,
            default: false
        },
        helpText: {
            type: String
        },
        baseUriControls: {
            type: String,
            required: true
        },
        relationString: {
            type: String
        },
        width: {
            type: Number
        },
        widgets: {
            type: Array,
            required: true
        },
        formValues: {
            type: Object,
            required: true
        },
        states: {
            type: Object,
            required: true
        },
        widgetListener: {
            type: Function,
            required: true
        },
        objectId: {
            type: String,
            required: false,
            default: 'new'
        },
        hasLocalizedProperties: {
            type: Boolean,
            default: false
        },
    },
    methods: {
        /**
         * Check if we need to show the widget width the given id
         * @param id
         * @returns {boolean|*}
         */
        checkVisibility(id) {
            if (this.states.visibility[id] !== undefined) return this.states.visibility[id];
            return true;
        },
        /**
         * Get the width for the widget width the given Id
         * @param id
         * @returns {number|*}
         */
        getInputWidth(id) {
            if (this.form.layout.width[id]) return this.form.layout.width[id];
            else return 12;
        },
        /**
         * Collect all the attributes that we need to pass to render the given widget
         * @param widget
         * @returns {*&{currentObjectId, baseUri: ({default(): string, type: String | StringConstructor, required: boolean}|{default(): string, type: String | StringConstructor, required: boolean}|{default(): string, type: String | StringConstructor, required}|*), relationString: methods.relationString}}
         */
        widgetAttributes(widget) {
            let attributes = {
                ...widget.attributes,
                ...widget.formControl,
                baseUri: this.baseUriControls,
                relationString: this.relationString,
                currentObjectId: this.objectId
            };

            // check if we have bindings
            if(widget.bindings) {
                Object.entries(widget.bindings).filter(([name, value]) => {
                    attributes[name] = this.formValues[value];
                });
            }

            Object.keys(widget).forEach(property => {
                // add other props from the widgets
                if(! ['formControl', 'attributes','slot'].includes(property) && widget[property] !== null) {
                    attributes[property] = widget[property];
                }
            });

            return attributes;
        }
    }
}, 'form/ak-form-group.html');