Vue.asyncComponent('ak-drawer', {
    data: () => {
        return {
            q: null,
            pinned: false,
        }
    },
    props: {
        showDrawer: {
            type: Boolean,
            required: false,
            default: ''
        }
    },
    model: {
        prop: 'showDrawer',
        event: 'close'
    },
    methods: {
        togglePinned() {
            this.pinned = !this.pinned;

            // We set the current pinned state as a cookie
            // So when the user logs back in the drawer is in the same state
            localStorage.setItem('ak-drawer-pinned', this.pinned);

            // Notify the parent component that the menu is pinned
            this.$emit('togglePinned', this.pinned);
        },
        close() {
            if ( ! this.pinned) {
                this.$emit('close', false);
            }
        },
        checkPinnedStatus() {
            this.pinned = localStorage.getItem('ak-drawer-pinned') === 'true';
            this.$emit('togglePinned', this.pinned);
        }
    },
    created() {
        this.checkPinnedStatus();
    }
}, 'wrappers/ak-drawer.html');