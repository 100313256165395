Vue.asyncComponent('ak-stacked-window', {
	name: 'ak-stacked-window',
	data() {
		return {
			show: false,
			layerDepth: 0,
		}
	},
	props: {
		closable: {
			type: Boolean,
			default: true
		},
		showClose: {
			type: Boolean,
			default: true
		}
	},
	computed: {
		depthClass() {
			return "ak-stacked-window--depth-" + this.layerDepth;
		}
	},
	methods: {
		setLayerDepth(layerDepth = 0) {
			let parent = this.$parent;
			while (parent) {
				parent = parent.$parent;

				if (parent && parent.$options.name == this.$options.name) {
					layerDepth++;
					
					if (layerDepth > 3) layerDepth = 3;

					parent.layerDepth = layerDepth;
				}
			}
		},
		close() {
			this.setLayerDepth(-1);
			this.$emit('close');
		},
		attemptClose() {
			// Check if this is closable
			if (!this.closable) return;
			// Check if there is an attemptClose listener bound
			if (this.$listeners.attemptClose) this.$emit('attemptClose', this.close);
			else if (this.closable) {
				// if no attemptClose listener is bound, just close
				this.close();
			}
		},
		amountOfChildStackedWindows() {
			let children = this.$children;
			let amountOfChildStackedWindows = 0;

			while (children) {
				let childChildren = [];

				children.forEach((child) => {
					if (child.$options.name == this.$options.name) {
						amountOfChildStackedWindows++;
					}
					if(child.$children) {
						childChildren = [...childChildren, ...child.$children];
					}
				});

				if(childChildren.length) {
					children = childChildren;
				} else {
					children = null;
				}
			}

			return amountOfChildStackedWindows;
		}
	},
	created() {
		// fix for multiple nexted windows
		this.$nextTick(() => {
			this.show = true;
		});
		this.setLayerDepth();
		this.$emit('open');
	},
	mounted: function() {
		this.$root.$el.append(this.$el);
	},

	destroyed: function() {
		if(this.$el.parentNode) {
			this.$el.parentNode.removeChild(this.$el);
		}
	}
}, 'utilities/ak-stacked-window.html');