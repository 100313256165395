import operationHandler from './helpers/operation-handler';
import ObjectHelpers from './helpers/object-helpers';
import { copyToClipboard } from './helpers/copy-to-clipboard';
import ConfirmUnsavedChangesHandler from './helpers/confirm-unsaved-changes-handler';
import NumberHelpers from "./helpers/number-helpers";
import GlobalConfirm from "./helpers/global-confirm";

// create a confirmHandler for the whole application to use
const confirmUnsavedChangesHandler = (new ConfirmUnsavedChangesHandler());

export const helpersPlugin = {
    install: (Vue) => {
        Vue.prototype.$operationHandler = (value, opration, valueToCompare) => new operationHandler(value, opration, valueToCompare);
        Vue.prototype.$copyToClipboard = (value) => copyToClipboard(value);
        Vue.prototype.$deepEqual = (object1, object2) => ObjectHelpers.deepEqual(object1, object2);
        Vue.prototype.$isInt = (number) => NumberHelpers.isInt(number);
        Vue.prototype.$isFloat = (number) => NumberHelpers.isFloat(number);
        Vue.prototype.$confirmUnsavedChangesHandler = confirmUnsavedChangesHandler;
        Vue.prototype.$globalConfirm = Vue.observable(new GlobalConfirm());
    }
}