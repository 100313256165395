Vue.component('ak-relation-resolver', {
    functional: true,
    name: "AkRelationResolver",
    props: {
        relationString: {
            type: String,
            default: '',
            required: true
        },
        relationReference: {
            type: String,
            default: '',
            required: false
        },
        enableForeignList: {
            type: Boolean,
            default: false,
            required: false,
        },
        defaultIdsSelected: {
            type: Array,
            required: false,
            default: () => {return []}
        }
    },
    render(createElement, { props, listeners, slots }) {
        let relation = props.relationString.split(':');

        let currentType = relation.shift();
        let currentBundle = relation.shift();
        let currentDefinition = relation.shift();
        if(currentType == 'grid') {
            // if we need to render a grid
            let leftOverRelationString = relation.length ? relation.join(':') : undefined;

            return createElement("ak-stacked-window", {
                props: {
                    showClose: false,
                },
                on: {
                    close: listeners.close,
                },
                scopedSlots: {
                    default: childProps => createElement('ak-relation-data-table', {
                        props: {
                            bundle: currentBundle,
                            definition: currentDefinition,
                            relation: props.relationReference,
                            defaultIdsSelected: props.defaultIdsSelected,
                            childRelationString: leftOverRelationString
                        },
                        on: {
                            save: listeners.updateRelations,
                            close: childProps.close,
                            refresh: listeners.updateRelations,
                        }
                    })
                }
            })
        } else if(currentType == 'form') {
            // if we have to render a form
            let currentObjectId = relation.shift();
            let leftOverRelationString = relation.length ? relation.join(':') : undefined;
            // console.log(listeners);
            return createElement("ak-stacked-window", {
                props: {
                    showClose: false,
                },
                on: {
                    close: listeners.close,
                },
                scopedSlots: {
                    default: childProps => createElement('ak-form', {
                        props: {
                            bundle: currentBundle,
                            definition: currentDefinition,
                            objectId: currentObjectId,
                            relationString: leftOverRelationString,
                        },
                        on: {
                            save: childProps.close,
                            cancel: childProps.close,
                            saveDataObject: (data) => {
                                if(!props.enableForeignList && data.dataObjectId &&  listeners.updateRelations) {
                                    listeners.updateRelations([data.dataObjectId]);
                                    return;
                                }
                                listeners.refresh([data.dataObjectId]);
                            }
                        },
                    })
                }
            });
        }
    }
}, false);
