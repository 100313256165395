import FormElementMixin from '../mixins/FormElementMixin.js';
import FormElementWidthPlaceholdersMixin from "../mixins/FormElementWidthPlaceholdersMixin";

Vue.asyncComponent('ak-textarea-width-placeholders', {
    mixins: [FormElementMixin, FormElementWidthPlaceholdersMixin],
    props: {
        rows: {
            type: Number,
            required: false,
            default: 5
        }
    }
}, 'form/controls/ak-textarea-width-placeholders.html');