Vue.asyncComponent('ak-contextual-widgets', {
    data() {
        return {
            show: false
        }
    },
    props: {
        title: {
            type: String,
            required: false,
        },
        widgets: {
            type: Object,
            required: true,
            default: () => {}
        },
        rowId: {
            type: String,
            required: true
        },
        callbackUri: {
            type: String,
            required: false,
            default: () => window.location.toString()
        }
    },
    computed: {
        isMobile() {
            return window.innerWidth < 768;
        }
    },
    methods: {
        handleEdit(data) {
            this.show = false;
            this.$emit('edit', data);
        },
        handleDelete(data) {
            this.show = false;
            this.$emit('delete', data);
        },
    }
}, 'grid/layout/ak-contextual-widgets.html');