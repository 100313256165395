Vue.asyncComponent('ak-search', {
    data() {
        return {
            showPopover: false,
            hideTooltip: false,
            localValue: this.value,
        }
    },
    props: {
        value: {
            type: String,
            required: true,
        },
        search: {
            type: Object,
            required: true,
        }
    },
    model: {
        prop: 'value',
        event: 'input'
    },
    watch: {
        value() {
            this.localValue = this.value;
        }
    },
    computed: {
        currentValue: {
            get() {
                return this.localValue;
            },
            set(e) {
                this.localValue = e;
                this.$emit('input', e);
            }
        },
        smartSearchTerms: {
            get() {
                return this.search.terms.map(term => {
                    if(!term.isLiteral) {
                        return term.text;
                    }
                })
            },
            set(newValue) {
                this.search.terms.map(term => {
                    if(!term.isLiteral && !newValue.includes(term.text)) {
                        this.$emit('searchLiteral', term.text);
                    }
                });
            }
        },
        smartSearch() {
            let smart = false;
            if(! ('terms' in this.search)) {
                return smart;
            }

            this.search.terms.forEach(item => {
                if(item.type != "text") {
                    smart = true;
                }
            })

            return smart;
        },
        searchDescription() {
            let description = '';

            if(this.search.terms) {
                description = '';
                this.search.terms.forEach(item => {
                    //@todo use this when translations are managed by the backend
                    // description += item.descr.text;
                    description += this.$t(item.descr.text, this.$applyFormatting(item.descr.placeholders,item.descr.placeholderFormatting)) + ' ';
                })
            }

            return description;
        }
    },
    methods: {
        toggleShowPopover() {
            this.showPopover = !this.showPopover;
            this.hideTooltip = true;
        },
        closePopover() {
            this.showPopover = false;
        }
    }
}, 'grid/search/ak-search.html');