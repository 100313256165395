Vue.asyncComponent('ak-filters-dropdown', {
    props: {
        filters: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            open: false,
        }
    },
    methods: {
        toggleDropdown() {
            this.open = !this.open;
        },
        closeDropdown() {
            this.open = false;
        },
        filterAdded(e) {
            this.$emit('filterAdded', e);
            this.$nextTick(this.closeDropdown);
        }
    }
}, 'grid/filters/ak-filters-dropdown.html');