Vue.asyncComponent('ak-popover-menu-item', {
    data() {
        return {
            open: false,
            showConfirm: false,
        }
    },
    props: {
        title: {
            type: String,
            required: false,
        },
        href: {
            type: String,
            required: false,
        },
        openInNewTab: {
            type: Boolean|Number,
            required: false,
        },
        active: {
            type: Boolean,
            required: false,
        },
        showArrow: {
            type: Boolean,
            required: false,
            default: true,
        },
        colorMode: {
            type: String,
            required: false,
        },
        icon: {
            type: String,
            required: false,
        },
        image: {
            type: String,
            required: false,
        },
        description: {
            type: String,
            required: false,
        },
        enableConfirm: {
            type: Boolean,
            required: false,
            default: false
        },
        confirmTitle: {
            type: String,
            required: false,
        },
        confirmText: {
            type: String,
            required: false,
        },
        disabled: {
            type: Boolean,
            required: false,
        },
    },
    methods: {
        click(e) {
            e.preventDefault();

            if (this.enableConfirm) {
                this.showConfirm = true;
                return;
            }

            this.$emit('click', e);

            if (this.href) {
                this.$actionHandler.handleRoute({route:this.href, target:this.openInNewTab ? '_blank' : null});
            }
        },
        confirm(e) {
            this.$emit('click', e);
            if (this.href) {
                this.$actionHandler.handleRoute({route:this.href, target:this.openInNewTab ? '_blank' : null});
            }
        }
    }
}, 'layout/partials/ak-popover-menu-item.html');