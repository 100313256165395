import FormElementMixin from '../mixins/FormElementMixin.js';

Vue.asyncComponent('ak-autocomplete', {
    mixins: [FormElementMixin],
    props: {
        selectOptions: {
            type: Array,
            required: false,
            default: () => []
        },
        allowMultiple: {
            type: Boolean|Number,
            required: false,
            default: false
        },
        filterMethode: {
            required: false,
        },
        valueProp: {
            type: String,
            required: false,
            default: 'value'
        },
        minCharCount: {
            type: Number,
            required: false,
            default: 3
        }
    },
    data() {
        return {
            search: '',
            highlightedIndex: -1,
            showResults: false,
            results: [],
        }
    },
    computed: {
        selected() {
            if ( ! this.currentValue) {
                return [];
            }

            return this.allowMultiple  ? this.currentValue : [this.currentValue];
        },
        selectedItems() {
            let out = [];

            this.selected.forEach(selectedValue => {
                out.push(this.selectOptions.find(item => item[this.valueProp] === selectedValue));
            })

            return out;
        }
    },
    methods: {
        onFocus() {
            this.showResults = true;
        },
        onBlur(e) {
            this.showResults = false;
            this.highlightedIndex = -1;
        },
        async onChange() {
            if(this.search.length < this.minCharCount) {
                return;
            }

            if (this.filterMethode) {
                this.results = this.filterMethode(this.search);

                // check if we are working with promises
                if(this.isPromise(this.results)) {
                    this.results = await this.results;
                }
            } else {
                this.results =  this.filterResult(this.search);
            }
        },
        filterResult(search) {
            if( ! search) {
                return [];
            }

            return this.selectOptions.filter(option => {
                return option.label.toLowerCase().indexOf(search.toLowerCase()) > -1;
            });
        },
        resultActive(result) {
            return this.allowMultiple && this.currentValue ?
                    this.currentValue.includes(result[this.valueProp]) :
                    this.currentValue === result[this.valueProp];
        },
        setResult(result) {
            if (this.allowMultiple) {
                if( ! this.currentValue) {
                    this.currentValue = [];
                }

                this.currentValue.push(result[this.valueProp]);
                this.search = '';
                this.results = this.filterResult(this.search);
            } else {
                this.currentValue = result[this.valueProp];
                this.search = result.label ? result.label : '';
            }
        },
        isPromise(variable) {
            if (variable !== null &&
                typeof variable === 'object' &&
                typeof variable.then === 'function' &&
                typeof variable.catch === 'function') {
                return true;
            }

            return false;
        },
        /** when we press backspace and no search string is present
         * we remove a part of the selected items if multiple
         * else we reset the currentValue
         */
        deleteItem() {
            if(this.search !== '') {
                return;
            }

            if( ! this.currentValue) {
                return;
            }

            if(this.allowMultiple && this.currentValue) {
                this.currentValue.pop();
            } else {
                this.currentValue = null;
            }
        },
        highlightedUp(e) {
            e.preventDefault();
            let newIndex = this.highlightedIndex + 1;

            if( (this.results.length - 1) < newIndex) {
                newIndex = (this.results.length - 1)
            }

            if(! this.results.length) {
                newIndex = 0;
            }

            this.highlightedIndex = newIndex;
        },
        highlightedDown(e) {
            e.preventDefault();
            let newIndex = this.highlightedIndex - 1;

            if( 0 > newIndex) {
                newIndex = 0
            }

            this.highlightedIndex = newIndex;

        },
        selectHighlighted() {
            this.setResult(this.results[this.highlightedIndex]);
        }
    },
    created() {
        if (this.value) {
            this.filterMethode
        }
    }
}, 'form/controls/ak-autocomplete.html');