import FormElementMixin from '../mixins/FormElementMixin.js';

Vue.asyncComponent('ak-wysiwyg', {
    mixins: [FormElementMixin],
    props: {
        configOptions: {
            type: Object|Array,
            required: true
        },
    },
    computed: {
        currentConfigOptions() {
            return {
                ...this.configOptions,
                callbacks: {
                    focus: this.focus,
                    blur: this.blur,
                    ...this.$currentListeners
                }
            }
        }
    }
}, 'form/controls/ak-wysiwyg.html');