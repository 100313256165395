/**
 * Helpers for numbers
 */
export default class NumberHelpers {

    /**
     * Check if number is of type int
     * @param number
     * @returns {boolean}
     */
    static isInt(number) {
        return number === +number && number === (number|0);
    }

    /**
     * Check if number is of type float
     * @param number
     * @returns {boolean}
     */
    static isFloat(number) {
        return number === +number && number !== (number|0);
    }
}