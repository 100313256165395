import FormElementMixin from '../mixins/FormElementMixin.js';

Vue.asyncComponent('ak-relation', {
    mixins: [FormElementMixin],
    props: {
        relationString: {
            type: String,
        },
        bundleId: {
            type: String,
        },
        definitionId: {
            type: String,
        },
        currentObjectId: {
            type: String,
        },
        relation: {
            type: Object,
        },
        enableForeignList: {
            type: Boolean,
            default: true
        },
        baseUri: {
            type: String,
            required: false,
            default: ''
        },
    },
    data() {
        return {
            currentRelation: null,
            deleteDialog: false,
            deleteDialogItemId: null,
        }
    },
    computed: {
        renderRelationResolver() {
            let relation = this.getActiveRelation();
            if(! relation) {
                return false;
            }

            let fieldId = relation.shift();

            if(fieldId == this.id) {
                return true;
            }
            
            return false;
        },
        currentRelationString() {
            let relation = this.getActiveRelation();
            relation.shift();
            return relation.join(':');
        },
        selectedItems() {
            this.currentRelation.selected.forEach((item) => {
                item.widgets = {...this.relation.widgets}
            });

            return this.currentRelation.selected;
        },
    },
    watch: {
        currentRelation: {
            deep: true,
            handler() {
                this.setCurrentValue(this.currentRelation.selected);
            }
        },
    },
    methods: {
        /**
         *
         * @param data
         */
        async updateRelations(idsSelected) {
            const path = `/${this.bundleId}/${this.definitionId}/select-ids`;

            let param = {
                id: this.currentObjectId,
            }

            // if we don't use the list we need to merge the selected Id every time
            if(! this.enableForeignList) {
                idsSelected = [...this.currentRelation.selected.map(selectedItem => selectedItem.id), ...idsSelected];

                // make sure we have unique ids
                idsSelected = [...new Set(idsSelected)];

                param.foreignIds = idsSelected.join(',');
            }


            param.foreignIds = idsSelected.join(',');

            // notify the backend of the selection so we get an updated relation.selected array
            const relation = await this.$get(path, param);

            if(relation) {

                // Keep the addOnValues
                let addOnValues = [];

                // Keep all the current addOnValues
                for(const key in this.currentRelation.selected) {
                    // Add each existing addOnValue in an array
                    addOnValues[this.currentRelation.selected[key].id] = this.currentRelation.selected[key].addOnValues;
                }

                // Set the new addOnValues of the new selection
                for(const key in relation.selected) {
                    let selectedRelation = relation.selected[key];
                    let selectedId = selectedRelation.id;

                    // Check if we have addOnValue from the previous selection
                    if(addOnValues.hasOwnProperty(selectedId)) {
                        relation.selected[key].addOnValues = addOnValues[selectedId];
                    }
                }

                this.currentRelation = relation;
            }
        },
        /**
         *
         * @returns {{name: string, params: {}}}
         */
        relationUrl() {
            let params = {};
            params = Object.assign(params, this.$route.params);

            let currentRelationAddon = this.id + ':grid:' + this.currentRelation.foreignList.bundle + ':' + this.currentRelation.foreignList.id;

            // if foreignList is disabled show the form imidiatly and add the dataObject to the selection on save
            if(! this.enableForeignList) {
                currentRelationAddon = this.id + ':form:' + this.currentRelation.foreignList.bundle + ':form:new';
            }

            if(params.relationString != undefined) {
                params.relationString += ':' + currentRelationAddon;
            } else {
                params.relationString = currentRelationAddon;
            }

            return {
                name: this.$route.name,
                params: params,
                query: this.$route.query
            }
        },
        editSelectedUrl(selectedItemId) {
            // we start from the currentRelationUrl because this has the logic of enableForeignList + addon
            let to = this.relationUrl()
            let relationString = to.params.relationString;
            relationString = relationString.substr(0, relationString.indexOf(':new'));
            to.params.relationString = relationString + ':' + selectedItemId;

            return this.$router.resolve(to).href;
        },
        /**
         *
         * @returns {*}
         */
        getActiveRelation() {
            let relationString = this.relationString;

            if(! relationString) {
                return false;
            }

            return relationString.split(':');
        },
        /**
         *
         * @param widget
         * @param widgetKey
         * @param item
         * @returns {*}
         */
        addOnWidgetAttributes(widget, widgetKey, item) {
            // if we have a form control we check if we need to set a value
            let out = { ...widget.formControl, ...widget.attributes, id: widget.id, baseUri: this.baseUri };

            // if we hava a value add it
            if(!out.value && item.addOnValues[widgetKey]) {
                out.value = item.addOnValues[widgetKey];
            }

            return out;
        },
        /**
         *
         * @param id
         */
        openDeleteDialog(id) {
            this.deleteDialog = true;
            this.deleteDialogItemId = id;
        },
        /**
         *
         * @param id
         */
        deleteSelected(id) {
            // remove the item from the selected array
            this.currentRelation.selected = this.currentRelation.selected.filter((item, key) => item.id != id);
        },
        /**
         *
         * @returns {Array}
         */
        selectedIds() {
            return this.currentRelation.selected.length ?
                this.currentRelation.selected.map(item => { return item.id }) :
                [];
        },
        /**
         *
         * @param widgetKey
         * @param item
         * @param value
         */
        handleWidgetInput( widgetKey, item, value) {
            if(!value) return;

            // we can't just set the addOnValues like addOnValues[widgetKey] = value beceause with a new item vue wil not pickup on these changes
            let currentSelection = this.currentRelation.selected.find(selectedItem => selectedItem.id == item.id);
            let addOnValues = {...currentSelection.addOnValues};

            addOnValues[widgetKey] = value;
            currentSelection.addOnValues = addOnValues;
        },
        /**
         *
         * @param id
         * @param values
         */
        async validateAddOnValue(id, values, item) {
            if (!id) return;
            const path = `/${this.bundleId}/${this.definitionId}/validate-add-on`;
            const validateData = {
                "addOnId": id,
                "addOnValues": { ...values }
            };

            try {
                const result = await this.$post(path, validateData);
                let widget = this.currentRelation.selected.find(selectedItem => selectedItem.id == item.id).widgets.addOns.find(item => item.id == id);
                widget.formControl = { ...widget.formControl, ...result[id] };

                // Do something
            } catch (error) {
                console.log(error);
            }
        },
        /**
         *
         * @param selected
         */
        setCurrentValue(selected) {
            let out = [];
            
            selected.forEach(item => {
                out.push({
                    id: item.id,
                    addOnValues: item.addOnValues,
                    entry: item.entry ? item.entry : null
                });

            });

            this.currentValue = out;
        },
        formatStateOutput(e) {
            // if we have no value return it
            if(! e) {
                return e;
            }

            // check if we can have multiple values or not
            if (! this.relation.enableMultiple) {
                // if we can only have one value return the first id;
                return Array.isArray(e) && e[0] ?
                        e[0].id :
                        e;
            } else {
                return e.map(value => value.id);
            }
        },
        /**
         * Trigger close
         */
        triggerClose() {
            this.$router.back();
        }
    },
    created() {
        // we need to disconnect the relation prop from the current relation
        // state otherwise the selected items wil change every time another relation is opened
        this.currentRelation = this.relation;
    }
}, 'form/controls/ak-relation.html');
