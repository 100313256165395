Vue.asyncComponent('ak-card', {
    props: {
        title: {
            type: String,
            default: '',
            required: false,
        },
        href: {
            type: String,
            default: '',
        },
        body: {
            type: String,
            default: '',
            required: false,
        },
        img: {
            type: String,
            required: false,
        },
        active: {
            type: Boolean,
        }
    },
    methods: {
        click(e) {
            if(this.href === '') {
                e.preventDefault();
            }

            this.$emit('click', e);
        }
    }
}, 'layout/ak-card.html');