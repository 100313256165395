Vue.asyncComponent('ak-form-decorator', {props: {
        id: {
            type: String,
            default: '',
            required: true,
        },
        title: {
            type: String,
            default: '',
            required: false,
        },
        icon: {
            type: String,
            required: false,
        },
        helpText: {
            type: String,
            default: '',
            required: false,
        },
        error: {
            type: String,
            default: '',
            required: false,
        },
        hasError: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean,
            default: false
        },
        value: {
            type: String|Array|Number,
            required: false,
        },
        isLocalized: {
            type: Boolean,
            default: false,
        }
    }
}, 'form/ak-form-decorator.html');