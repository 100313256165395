Vue.asyncComponent('ak-menu-item', {
    name: '',
    data: () => {
        return {
            childMenuOpen: false,
            childItemActive: false,
            active: false,
        }
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        href: {
            type: String,
            required: true,
        },
        items: {
            type: Array,
            required: false,
        },
        target: {
            type: String,
            required: false,
            default: '_self'
        },
        isSubMenu: {
            type: Boolean,
            required: false,
            default: false
        },
        parentSearchString: {
            type: String,
        },
        showChildren: {
            type: Boolean,
            required: false,
            default: false,
        }
    },
    computed: {
        isFullUrl() {
            return this.href.substring(0, 4) === 'http';
        },
        hasChildMenu() {
            return (this.childMenu() != undefined || (this.items && this.items.length))
        }
    },
    watch: {
        '$route': {
            handler() {
                this.childItemActive = false;
                this.checkActive();
            },
            deep: true
        }
    },
    methods: {
        childMenu() {
            return this.$children.find(
                child => child.$options.name === "ak-menu"
            );
        },
        onClickMenuItem(event) {
            let childMenu = this.childMenu(this);

            if (childMenu) {
                this.childMenuOpen = !this.childMenuOpen;
                childMenu.show = !childMenu.show;
                if(event) {
                    event.preventDefault();
                }

                return;
            }

            if (this.href) {
                this.$actionHandler.handleRoute({route:this.href, target:this.target});
            }
            this.$emit('click');
        },
        checkActive() {
            if (this.isFullUrl) {
                this.active =  window.location.href === this.href;
            } else {
                this.active =  this.$route.path == this.href;
            }

            if (this.active) {
                this.$emit('isActive', this.active);
            }
        },
        setChildItemActive() {
            this.childItemActive = true;
        }
    },
    created() {
        this.checkActive();
    }
}, 'layout/ak-menu-item.html');