const FormElementWidthPlaceholdersMixin = {
    data() {
        return {
            highlightedIndex: 0
        }
    },
    props: {
        openDelimiter: {
            type: String,
            default: '%{'
        },
        closeDelimiter: {
            type: String,
            default: '}'
        },
        triggerDelimiter: {
            type: String,
            default: '%'
        },
        placeholders: {
            type: Object,
        }
    },
    computed: {
        valueWidthPlaceholders() {
            let value = this.currentValue;

            if (value) {
                value = value.replaceAll(this.openDelimiter, '<div class="ak-form__placeholder">');
                value = value.replaceAll(this.closeDelimiter, '</div>');
            }

            return value;
        },
        /**
         * The value without any active placeholders in it
         * @returns {*}
         */
        cleanValue() {
            let value = this.currentValue;

            this.getPlaceholderValue([], this.placeholders).forEach(placeholder => {
                value = value.replaceAll(`${this.openDelimiter}${placeholder.label}${this.closeDelimiter}`, '');
            });

            return value;
        },
        /**
         * Check if we need to show the list width placeholders
         * @returns {boolean}
         */
        showPlaceholderList() {
            if (! this.currentValue) {
                return false;
            }

            let value = this.currentValue;

            this.getPlaceholderValue([], this.placeholders).forEach(placeholder => {
                value = value.replace(`${this.openDelimiter}${placeholder.label}${this.closeDelimiter}`, '');
            });

            return this.cleanValue.includes(this.triggerDelimiter);
        },
        /**
         * Determine the search string for the placeholder
         * @returns {string|null}
         */
        search() {
            if(! this.showPlaceholderList) {
                return null;
            }
            // Get the value after the trigger delimiter
            let search = this.cleanValue.substring(this.cleanValue.indexOf(this.triggerDelimiter) + 1, this.cleanValue.length);

            // if we have other text after this
            if(search.indexOf(' ') !== -1) {
                search = search.substr(0, search.indexOf(' '));
            }

            return search;
        },
        /**
         * Get the active placeholders for the current value
         * @returns {*}
         */
        activePlaceholders() {
            const placeholders = this.getPlaceholderValue([], this.placeholders)
            return placeholders.filter(placeholder => placeholder.label.includes(this.search));
        }
    },
    methods: {
        /**
         * Insert the placeholder into the current string
         * @param placeholder
         */
        insertPlaceholder(index, event) {
            const placeholder = this.activePlaceholders[index];

            if (!placeholder) {
                return;
            }

            event.preventDefault();

            // extra regex to not replace the existing placeholders
            const extraDelimeterCheck = this.openDelimiter.includes(this.openDelimiter) ?
                    '(?!(' + this.openDelimiter.replace(this.triggerDelimiter, '') + '))'
                    : '';
            let re = new RegExp(`${this.triggerDelimiter}${extraDelimeterCheck}${this.search}`, "gi");

            this.currentValue = this.currentValue.replace(re, `${this.openDelimiter}${placeholder.label}${this.closeDelimiter}`);
        },
        /**
         * Get the placeholders in one object
         * @param out
         * @param placeholders
         * @param activeKey
         * @returns {*}
         */
        getPlaceholderValue(out, placeholders, activeKey = '') {
            for(const propperty in placeholders) {
                const value = placeholders[propperty];
                activeKey += activeKey ? `.${propperty}` : propperty;

                if (typeof value !== 'object') {
                    out.push({
                        value: value,
                        label: activeKey
                    });
                    continue;
                }

                this.getPlaceholderValue(out, value, activeKey);
            }

            return out;
        },
        /**
         * Higlighted index up
         * @param e
         */
        highlightedUp(e) {
            e.preventDefault();
            let newIndex = this.highlightedIndex + 1;

            if( (this.activePlaceholders.length - 1) < newIndex) {
                newIndex = (this.activePlaceholders.length - 1)
            }

            if(! this.activePlaceholders.length) {
                newIndex = 0;
            }

            this.highlightedIndex = newIndex;
        },
        /**
         * Higlighted index down
         * @param e
         */
        highlightedDown(e) {
            e.preventDefault();
            let newIndex = this.highlightedIndex - 1;

            if( 0 > newIndex) {
                newIndex = 0
            }

            this.highlightedIndex = newIndex;

        },
    }
}

export default FormElementWidthPlaceholdersMixin;