import FormElementMixin from '../mixins/FormElementMixin.js';

Vue.asyncComponent('ak-date-picker', {
    mixins: [FormElementMixin],
    data() {
        return {
            removeFromListener: ['input','updateState','blur'],
            selectedDate: null,
            dateFormat: 'DD/MM/YYYY',
        }
    },
    watch: {
        selectedDate(newValue) {
            if(this.$moment(this.currentValue).format(this.dateFormat) !== this.$moment(newValue).format(this.dateFormat)) {
                this.currentValue = this.$moment(this.selectedDate).toISOString(true);
                this.blur();
            }
        },
        currentValue(newValue) {
            if(this.$moment(newValue).format(this.dateFormat) !== this.$moment(this.selectedDate).format(this.dateFormat)) {
                this.selectedDate = this.$moment(this.currentValue).toDate();
            }
        }
    },
    created() {
		if (this.currentValue) {
            this.selectedDate = this.$moment(this.currentValue).toDate();
        }

    }
}, 'form/controls/ak-date-picker.html');