import FormElementMixin from '../mixins/FormElementMixin.js';
import FormElementWidthPlaceholdersMixin from "../mixins/FormElementWidthPlaceholdersMixin";

Vue.asyncComponent('ak-input-text-width-placeholders', {
    mixins: [FormElementMixin, FormElementWidthPlaceholdersMixin],
    props: {
        type: {
            type: String,
            default: 'text'
        },
        icon: {
            type: String,
        },
        showClear: {
            type: Boolean,
        }
    },
    methods: {
        triggerClear() {
            this.currentValue = "";
            this.$emit('clear', "");
        }
    }
}, 'form/controls/ak-input-text-width-placeholders.html');